import './Loading.css';
import React from 'react';
import { Container, Grid, LinearProgress, CircularProgress  } from '@material-ui/core';

const Loading = () => {
    return <Container className='loading-container'>
        <Grid
            className='loading-grid'
            container
            direction="column"
            justify="center"
            alignItems="center"
            ><CircularProgress color='secondary'/>Loading...</Grid>
    </Container>
};

export default Loading;