import Axios from 'axios';
import HttpStatus from 'http-status-codes';

const BASE = '/api';

const client = Axios.create({
	baseURL: BASE
});

function getLoginUrl() {
	return `${window.location.origin}${BASE}/auth/login?redirect=${encodeURIComponent(window.location)}`;
}

function forceLogin(url) {
	window.location = `${url}?login=${encodeURIComponent(getLoginUrl())}`;
}

const loginState = {};

function logout() {
	loginState.setLoginState(false);
	loginState.setUser(null);
}

function checkResponse(err) {
	if(err.response && err.response.status === HttpStatus.UNAUTHORIZED) {
		logout();
	}
	return Promise.reject(err);
}

export const uploadImage = (file, sku, index) => {
	const formData = new FormData();
	formData.append('file', file, 'img.png');
	return client.post(`/image/add/${sku}/${index}`, formData).catch(checkResponse);
};

export const removeImages = (sku, index) => {
	return client.get(`/image/remove/${sku}/${index}`).catch(checkResponse);
};

export const getItems = () => {
	return client.get('/ecomitems').then(res => res.data).catch(checkResponse);
};

export function isLoggedIn(setUser, setLoginState, setError) {
	loginState.setUser = setUser;
	loginState.setLoginState = setLoginState;
	loginState.setError = setError;
	setError(null);
	if(!loginState.promise) {
		loginState.promise = client.get('/auth/me').then(res => {
			loginState.setUser(res.data);
			loginState.setLoginState(true);
		}).catch(err => {
			if(err.response && err.response.status === HttpStatus.UNAUTHORIZED) {
				forceLogin(err.response.data.login);
				logout();
				return;
			}
			loginState.setError(err);
		});
	}
	return loginState.promise;
}
