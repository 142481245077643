import React from 'react';
import { render } from 'react-dom';
import Routing from './components/app/routing';

const target = document.querySelector('#root');

render(
	<Routing />,
	target
);
