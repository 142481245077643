import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function Confirm(props) {
	const {confirm, setConfirm, callBack, remove} = props;

	return (
		<div>
			<Dialog
				open={confirm}
				onClose={() => setConfirm(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Are you sure you want to override?</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
            By overriding, you will lose the current image.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => { setConfirm(false); }} color="secondary">
            Cancel
					</Button>
					<Button onClick={() => { setConfirm(true); callBack(true); }} color="primary" autoFocus>
						{remove ? 'Delete' : 'Override'}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
