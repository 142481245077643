import './Matrix.css';
import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import {useDropzone} from 'react-dropzone';
import { Input, ListItem, ListItemText, Button } from '@material-ui/core';
import { FixedSizeList } from 'react-window';
import Popover from '@material-ui/core/Popover';
import { uploadImage, removeImages } from '../../api';
import SearchIcon from '@material-ui/icons/Search';
import Confirm from './../dialog/Confirm';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useEffect } from 'react';
import validateImage from '../../helpers/validateImage';

const Cell = props => {
	const {sku, index, row, handleClickSnack, canDelete, canUpload} = props;
	let foto = row[`foto${index}`] || null;
	if (index === 0) {
		foto = row.main;
	}

	return <TableCell align="center">
		<MyDropzone
			sku={sku}
			index={index}
			handleClickSnack={handleClickSnack}
			promptConfirm={!!foto}
			canDelete={canDelete}
			canUpload={canUpload}
			content={
				foto && <img className='cell-image' src={foto} loading="lazy" />
			}/>
	</TableCell>;
};

function MyDropzone(props) {
	const {sku, index, promptConfirm, handleClickSnack, canDelete, canUpload} = props;
	const [confirm, setConfirm] = React.useState(false);
	const [confirmDelete, setConfirmDelete] = React.useState(false);
	const [file, setFile] = React.useState();
	const [tempImage, setTempImage] = React.useState();
	const [content, setContent] = React.useState();

	useEffect(() => {
		if (props.content) {
			setContent(props.content);
		}
	}, []);

	const upload = (_file, _sku, _index) => {
		if (!validateImage(file)) {
			return handleClickSnack(true);
		}
		setTempImage(URL.createObjectURL(file));
		setConfirm(false);
		uploadImage(_file, _sku, _index).then(e => { console.log(e); setFile(); });
	};

	const onDrop = useCallback((f) => {
		setFile(f);
		if (promptConfirm) {
			setConfirm(true);
		} else {
			upload(f[0], sku, index);
		}
	}, []);
	const {getRootProps, getInputProps} = useDropzone({onDrop});

	const deleteImage = () => {
		if (tempImage) {
			setTempImage();
		}
		setContent(false);
		setConfirmDelete(false);
		removeImages(sku, index);
	};

	if(!canUpload) {
		return <div className='image-cell'>
			<div className='ref-holder'>
				{content}
			</div>
		</div>;
	}

	return (
		<>
			<Confirm confirm={confirm} setConfirm={setConfirm} callBack={() => { upload(file[0], sku, index); }} />
			<Confirm confirm={confirmDelete} remove setConfirm={setConfirmDelete} callBack={() => { deleteImage(); }} />
			<div {...getRootProps()} className='image-cell'>
				<input {...getInputProps()} />
				<div className='ref-holder'>
					{tempImage ? <img className='cell-image' src={tempImage} />
						: content || <Button variant='contained'><CloudUploadIcon /></Button>}
				</div>
				{
					(content || tempImage) && canDelete &&
					<div className='controls'>
						<Button onClick={(e) => { e.stopPropagation(); setConfirmDelete(true); }} variant='outlined' color="secondary" size='small'><DeleteForeverIcon fontSize='small' /></Button>
					</div>
				}

			</div>
		</>
	);
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{ id: 'sku', numeric: false, disablePadding: true, label: 'sku' },
	{ id: 'main', numeric: true, disablePadding: false, label: 'Itemphoto' },
	{ id: 'foto1', numeric: true, disablePadding: false, label: 'Ambiance 1' },
	{ id: 'foto2', numeric: true, disablePadding: false, label: 'Ambiance 2' },
	{ id: 'foto3', numeric: true, disablePadding: false, label: 'Ambiance 3' },
	{ id: 'foto4', numeric: true, disablePadding: false, label: 'Details' },
	{ id: 'foto5', numeric: true, disablePadding: false, label: 'Front' },
	{ id: 'foto6', numeric: true, disablePadding: false, label: 'Back' },
	{ id: 'foto7', numeric: true, disablePadding: false, label: 'Side' },
	{ id: 'foto8', numeric: true, disablePadding: false, label: 'Top/Bottom' },
	{ id: 'foto9', numeric: true, disablePadding: false, label: 'Other' }
];

function EnhancedTableHead(props) {
	const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{ 'aria-label': 'select all desserts' }}
					/>
				</TableCell>
				{headCells.map((headCell, index) =>
					<TableCell
						key={headCell.id}
						align='center'
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						{
							index === 0 ? <TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : 'asc'}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
								{orderBy === headCell.id ?
									<span className={classes.visuallyHidden}>
										{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
									</span>
									: null}
							</TableSortLabel>
								: headCell.label
						}
					</TableCell>
				)}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1)
	},
	highlight: theme.palette.type === 'light' ? {
		color: theme.palette.secondary.main,
		backgroundColor: lighten(theme.palette.secondary.light, 0.85)
	} : {
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.secondary.dark
	},
	title: {
		flex: '1 1 100%'
	}
}));

const EnhancedTableToolbar = (props) => {
	const classes = useToolbarStyles();
	const { numSelected, selectedGroup, setSelectedGroup, groups } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const renderRow = ({ index, style }) => {
		return (
			<ListItem button onClick={() => setSelectedGroup(groups[index])} style={style} key={index}>
				<ListItemText primary={`${groups[index]}`} />
			</ListItem>
		);
	};

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0
			}) + ' topBar'}
		>
			<Button aria-describedby={id} variant="contained" color="default" onClick={handleClick}>
				{selectedGroup}
			</Button>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				<FixedSizeList height={400} width={300} itemSize={46} itemCount={groups.length}>
					{renderRow}
				</FixedSizeList>
			</Popover>
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	table: {
		minWidth: 750
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	}
}));

export default function EnhancedTable(props) {
	const {rows, page, setPage, rowsPerPage, setRowsPerPage, selectedGroup, setSelectedGroup, groups, search, setSearch, handleClickSnack, canDelete, canUpload} = props;
	const classes = useStyles();
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('calories');
	const [selected, setSelected] = React.useState([]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = rows.map((n) => n.name);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		console.log(selectedIndex, name, newSelected);

		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

	const pagination = <TablePagination
		rowsPerPageOptions={['5', '10', '25', '100']}
		component="div"
		className='topnav'
		count={rows.length}
		rowsPerPage={rowsPerPage}
		page={page}
		onChangePage={handleChangePage}
		onChangeRowsPerPage={handleChangeRowsPerPage}
	/>;

	return (
		<>
			<div className='skuFinderHolder'>
				<Input value={search} onChange={(e) => setSearch(e.target.value)} placeholder='sku...'></Input><SearchIcon />
			</div>
			<div className={classes.root}>
				<Paper className={classes.paper}>
					<EnhancedTableToolbar selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} groups={groups} numSelected={selected.length} />
					{pagination}
					<TableContainer>
						<Table
							className={classes.table}
							aria-labelledby="tableTitle"
							size="medium"
							aria-label="enhanced table"
						>
							<EnhancedTableHead
								classes={classes}
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClick}
								onRequestSort={handleRequestSort}
								rowCount={rows.length}
							/>
							<TableBody>
								{
									stableSort(rows, getComparator(order, orderBy))
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, index) => {
											const {sku} = row;
											const isItemSelected = isSelected(row.name);
											const labelId = `enhanced-table-checkbox-${index}`;

											return (
												<TableRow
													hover
													aria-checked={isItemSelected}
													tabIndex={-1}
													key={row.name}
													selected={isItemSelected}
												>
													<TableCell padding="checkbox">
														<Checkbox
															onClick={(event) => handleClick(event, row.name)}
															checked={isItemSelected}
															inputProps={{ 'aria-labelledby': labelId }}
														/>
													</TableCell>
													<TableCell align="center" className='skucell'>
														{row.sku}
													</TableCell>
													{
														[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(index => {
															return <Cell handleClickSnack={handleClickSnack} index={index} sku={sku} row={row} key={index} canDelete={canDelete} canUpload={canUpload} />;
														})
													}
												</TableRow>
											);
										})}
								{emptyRows > 0 &&
								<TableRow style={{ height: 53 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
								}
							</TableBody>
						</Table>
					</TableContainer>
					{pagination}
				</Paper>

			</div>
		</>
	);
}
