import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import Table from '../../pages/home/Table';
import { isLoggedIn } from '../../api';

export default function App() {
	const [user, setUser] = useState(null);
	const [loginState, setLoginState] = useState(null);
	const [error, setError] = useState(null);
	useEffect(() => {
		if(loginState === null) {
			isLoggedIn(setUser, setLoginState, setError);
		}
	}, [setUser, setLoginState, setError, loginState]);
	if(error) {
		return <h1>error.message</h1>;
	} else if(!loginState) {
		return <h1>Loading...</h1>;
	} else if(!user.roles.length) {
		return <h1>Access denied</h1>;
	}
	const canDelete = user.roles.includes('ImageCompleter:DeleteImage');
	const canUpload = user.roles.includes('ImageCompleter:UploadImage');
	return <BrowserRouter>
		<QueryParamProvider ReactRouterRoute={Route}>
			<Route exact path="/" render={() => <Table canDelete={canDelete} canUpload={canUpload} />} />
		</QueryParamProvider>
	</BrowserRouter>;
}
