import './Table.css';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Matrix from '../../components/home/Matrix';
import { getItems } from '../../api';
import { useQueryParam, StringParam } from 'use-query-params';
import { Snackbar, Button, Input } from '@material-ui/core';
import { WindowTable } from 'window-table';
import SearchIcon from '@material-ui/icons/Search';
import { useDropzone } from 'react-dropzone';
import { uploadImage, removeImages } from '../../api';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import validateImage from '../../helpers/validateImage';
import Confirm from './../../components/dialog/Confirm';
import Loading from './Loading';

const imageSize = 'Small';
const baseSku = 697383;
const allOption = 'Alles';
const defaultRows = 100;


const CustomHeaderCell = props => {
	return (
	  <th className='tableCell-header'>
		<span>
		  {props.children} {/* A span inside Header Cell because why not */}
		</span>
	  </th>
	);
  }

const createData = (item) => {
	const {sku, collection, imageSmall} = item;
	const keys = Object.keys(item);
	const images = {};
	keys.forEach(key => {
		if (key === `moodImage1${imageSize}`) {
			images[`moodImage1${imageSize}`] = item[`moodImage1${imageSize}`];
		} else if (key === `moodImage2${imageSize}`) {
			images[`moodImage2${imageSize}`] = item[`moodImage2${imageSize}`];
		} else if (key === `moodImage3${imageSize}`) {
			images[`moodImage3${imageSize}`] = item[`moodImage3${imageSize}`];
		} else if (key === `detailImage${imageSize}`) {
			images[`moodImage4${imageSize}`] = item[`detailImage${imageSize}`];
		} else if (key === `frontImage${imageSize}`) {
			images[`moodImage5${imageSize}`] = item[`frontImage${imageSize}`];
		} else if (key === `backImage${imageSize}`) {
			images[`moodImage6${imageSize}`] = item[`backImage${imageSize}`];
		} else if (key === `sideImage${imageSize}`) {
			images[`moodImage7${imageSize}`] = item[`sideImage${imageSize}`];
		} else if (key === `topImage${imageSize}`) {
			images[`moodImage8${imageSize}`] = item[`topImage${imageSize}`];
		} else if (key === `otherImage${imageSize}`) {
			images[`moodImage9${imageSize}`] = item[`otherImage${imageSize}`];
		}
	});
	return {
		name: sku,
		sku,
		collection,
		main: imageSmall,
		foto1: images[`moodImage1${imageSize}`],
		foto2: images[`moodImage2${imageSize}`],
		foto3: images[`moodImage3${imageSize}`],
		foto4: images[`moodImage4${imageSize}`],
		foto5: images[`moodImage5${imageSize}`],
		foto6: images[`moodImage6${imageSize}`],
		foto7: images[`moodImage7${imageSize}`],
		foto8: images[`moodImage8${imageSize}`],
		foto9: images[`moodImage9${imageSize}`]
	};
};

const getAllProductGroups = (items) => {
	return [allOption, ...new Set(items.map(i => i.collection))].filter(i => i);
};

const Cell = props => {
	const {sku, index, image, canDelete, canUpload, handleClickSnack} = props;
	const [confirm, setConfirm] = React.useState(false);
	const [confirmDelete, setConfirmDelete] = React.useState(false);
	const [file, setFile] = React.useState();
	const [tempImage, setTempImage] = React.useState();
	const [content, setContent] = React.useState();

	const promptConfirm = !!image;
	const getTempImage = () => localStorage.getItem(sku+index);

	useEffect(() => {
		if ( (!getTempImage() || (getTempImage() && getTempImage() !== 'deleted')) && image) {
			setContent(<img className='cell-image' src={image} loading="lazy" />);
		}
	}, []);


	const upload = (_file, _sku, _index) => {
		if (!validateImage(_file)) {
			return handleClickSnack(true);
		}
		localStorage.setItem(_sku+_index, URL.createObjectURL(_file));
		setConfirm(false);
		uploadImage(_file, _sku, _index).then(e => { console.log(e); setFile(); });
	};

	const onDrop = useCallback((f) => {
		setFile(f);
		if (promptConfirm) {
			setConfirm(true);
		} else {
			upload(f[0], sku, index);
		}
	}, []);
	const {getRootProps, getInputProps} = useDropzone({onDrop});

	const deleteImage = () => {
		localStorage.setItem(sku+index, 'deleted');
		setContent(false);
		setConfirmDelete(false);
		removeImages(sku, index);
	};

	console.log(canUpload)

	if(!canUpload) {
		return <div className='image-cell'>
			<div className='ref-holder'>
				{content}
			</div>
		</div>;
	}

	return (
		<>
			<Confirm confirm={confirm} setConfirm={setConfirm} callBack={() => { upload(file[0], sku, index); }} />
			<Confirm confirm={confirmDelete} remove setConfirm={setConfirmDelete} callBack={() => { deleteImage(); }} />
			<div {...getRootProps()} className='image-cell'>
				<input {...getInputProps()} />
				<div className='ref-holder'>
					{getTempImage() && getTempImage() !== 'deleted' ? <img className='cell-image' src={getTempImage()} />
						: content || <Button variant='contained'><CloudUploadIcon /></Button>}
				</div>
				{
					(content || getTempImage() && getTempImage() !== 'deleted') && canDelete &&
					<div className='controls'>
						<Button onClick={(e) => { e.stopPropagation(); setConfirmDelete(true); }} variant='contained' color="secondary" size='small'><DeleteForeverIcon fontSize='small' /></Button>
					</div>
				}

			</div>
		</>
	);
};

const Home = ({ canDelete, canUpload }) => {
	const [pp] = useQueryParam('page', StringParam);
	const [search, ss] = useState('');
	const [searchH, ssH] = useState('');
	const inputEl = useRef(null);
	const [collection] = useQueryParam('collection', StringParam);
	const [selectedGroup, setSelectedGroup] = useState(allOption);
	const [drawerOpen, openDrawer] = useState(false);
	const [groups, setGroups] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(defaultRows);
	const [page, setPage] = useState(pp || 0);
	const [rows, setRows] = useState([]);
	const history = useHistory();
	const [openSnack, setOpenSnack] = React.useState(false);
	const [tempImageMapping, setTempImageMapping] = React.useState({});

	const setSearch = (value) => {
		ss(value);
		closeDraw();
	};

	useEffect(() => {
		localStorage.clear();
	}, []);

	const closeDraw = () => {
		setTimeout(() => openDrawer(false), 500);
	}

	const handleClickSnack = () => {
		console.log('click');
		setOpenSnack(true);
	};

	const setTempoImageMapping = (e) => {
		console.log(e);
		setTimeout(() => setTempImageMapping(), 1000)
	}

	const handleCloseSnack = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSnack(false);
	};

	const navigate = (p) => {
		setPage(+p);
		history.push(`/?page=${p}&collection=${selectedGroup}`);
	};

	const changeCollection = (value) => {
		history.push(`/?page=0&collection=${value}`);
		setPage(0);
		setSelectedGroup(value);
	};

	const initiate = async () => {
		let items = await getItems().catch(e => console.log(e));
		if (!items) {
			return null;
		}
		items = items.filter(item => +item.sku >= baseSku);
		const allCollections = getAllProductGroups(items);
		setGroups(allCollections);
		if (collection) {
			setSelectedGroup(collection);
		}
		setRows(items.map(i => createData(i)));
	};

	const ImageCellRenderer = props => {
		const {row, column} = props;
		return (
			<Cell canUpload={canUpload} canDelete={canDelete} setTempImageMapping={setTempoImageMapping} image={row[column.key]} sku={row['sku']} index={column['index']}  />
		);
	}

	const columns = [
		{key: 'sku', width: 100, title: 'Sku'},
		{key: 'main', width: 100, title: 'Item photo', index: 0, Component: ImageCellRenderer},
		{key: 'foto1', width: 100, title: 'Ambiance 1', index: 1, Component: ImageCellRenderer},
		{key: 'foto2', width: 100, title: 'Ambiance 2', index: 2, Component: ImageCellRenderer},
		{key: 'foto3', width: 100, title: 'Ambiance 3', index: 3, Component: ImageCellRenderer},
		{key: 'foto4', width: 100, title: 'Details', index: 4, Component: ImageCellRenderer},
		{key: 'foto5', width: 100, title: 'Front', index: 5, Component: ImageCellRenderer},
		{key: 'foto6', width: 100, title: 'Back', index: 6, Component: ImageCellRenderer},
		{key: 'foto7', width: 100, title: 'Side', index: 7, Component: ImageCellRenderer},
		{key: 'foto8', width: 100, title: 'Top/Bottom', index: 8, Component: ImageCellRenderer},
		{key: 'foto9', width: 100, title: 'Other', index: 9, Component: ImageCellRenderer}
	];

	const SearchComponent = () => {
		return <div className='skuFinderHolder'>
			<Input inputRef={inputEl} defaultValue={search} onKeyDown={(e) => {if (e.key === 'Enter') {openDrawer(false); setSearch(inputEl.current.value)}}} placeholder='sku...'></Input><SearchIcon onClick={() => setSearch(inputEl.current.value)} />
		</div>;
	}

	const drawerOptions = [{
		Component: SearchComponent
	}]

	useEffect(() => { initiate(); }, []);

	if (!rows.length) {
		return <Loading />
	}

	return <div>
		<div className='upper-row'>
			<SearchComponent />
		</div>
		<WindowTable
		//   HeaderCell={CustomHeaderCell}
		  headerCellInnerElementType="th"
			data={rows.filter(row => {
				if (search.length && row.sku.includes(search)) {
					return true;
				} else if (search.length && !row.sku.includes(search)) {
					return false;
				}
				return true;
			})}
			columns={columns}
			style={{height: '100vh'}}
		/>
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left'
			}}
			open={openSnack}
			autoHideDuration={2000}
			onClose={handleCloseSnack}
			message="ERROR: Image bigger than 10MB. Cannot upload images bigger than 10MB"
			action={
				<React.Fragment>
					<Button color="secondary" size="small" onClick={handleCloseSnack}>
            UNDO
					</Button>
					<Button size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
            Close
					</Button>
				</React.Fragment>
			}
		/>
	</div>;
};

export default Home;
